
import { Vue, Component } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { UserListInfo, roleListInfo } from '@/types/common'
import { ProjectUser } from '@/types/project'

@Component
export default class Person extends Vue {
  private loading = false
  private showAddUser = false
  private roleList: roleListInfo[] = []
  private projectUserList: ProjectUser[] = []
  private addUserInfo: { role: string; userIdList: [] } = {
    role: '',
    userIdList: []
  }

  private addUserInfoRules = {
    role: [{ required: true, message: '请选择角色', trigger: 'blur' }],
    userIdList: [{ required: true, message: '请选择人员', trigger: 'blur' }]
  }

  private userList: UserListInfo [] = []

  $refs!: {
    addUserInfo: ElForm;
  }

  created (): void {
    this.getProjectUserList()
  }

  // 获取项目人员列表
  getProjectUserList () {
    this.loading = true
    this.$axios.get(this.$apis.project.selectProjectUserByList, {
      projectId: this.$route.query.projectId
    }).then((res: {list: ProjectUser[]; total: number}) => {
      this.projectUserList = res.list
      this.loading = false
    })
  }

  // 获取人员角色列表
  getRoleList () {
    this.$axios.get(this.$apis.common.getRoleList, {}).then((res: roleListInfo[]) => {
      this.roleList = res
    })
  }

  // 获取用户列表
  getUserList (role: string) {
    this.userList = []
    this.$axios.get(this.$apis.common.getRoleUserList, {
      role: role
    }).then((res: UserListInfo[]) => {
      this.userList = this.removeArrayTwo(res, this.projectUserList)
    })
  }

  // 从列表清除已在项目的数据
  removeArrayTwo (userList: UserListInfo[], innerList: ProjectUser[]) {
    const arr: UserListInfo[] = []
    userList.forEach(item => {
      if (innerList.findIndex(i => i.userId === item.userId) < 0) {
        arr.push(item)
      }
    })
    return arr
  }

  // 添加项目人员时角色切换重新获取相应用户列表
  roleChange () {
    this.addUserInfo.userIdList = []
    this.getUserList(this.addUserInfo.role)
  }

  // 显示
  addUser () {
    this.getRoleList()
    this.showAddUser = true
  }

  // 添加项目管理人员
  commitAddUser () {
    this.$refs.addUserInfo.validate(valid => {
      if (valid) {
        this.$axios.post(this.$apis.project.insertProjectUser, {
          projectId: this.$route.query.projectId,
          userIdList: this.addUserInfo.userIdList
        }).then(() => {
          this.$message.success('新增成功')
          this.getProjectUserList()
          this.cancelAddUser()
        })
      }
    })
  }

  beforeCloseAddUser (done: any) {
    this.cancelAddUser()
    done()
  }

  // 取消添加项目管理人员
  cancelAddUser () {
    this.$refs.addUserInfo.resetFields()
    this.$refs.addUserInfo.clearValidate()
    this.showAddUser = false
  }

  // 删除项目人员
  deleteProjectUser (row: ProjectUser) {
    this.$confirm('确认删除' + row.name + '吗?', '删除', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      center: true
    }).then(() => {
      this.$axios.post(this.$apis.project.deleteDataProjectUser, {
        id: row.id
      }).then(() => {
        this.$message.success('删除成功')
        this.getProjectUserList()
      })
    })
  }
}
